const index = {
    AOneWayTicketToNishiwaga : 2,
    ActTogether : 3,
    AtlantisWay : 7,
    Bleu202TV : 0,
    BreakTheIce : 15,
    ConterTime : 10,
    DaftPunkClip : 13,
    DashBoard : 5,
    FriederNakeRemake : 14,
    InsectTowerDefense : 11,
    Memap : 4,
    MinecraftEditor : 6,
    OpenGLGalaxie : 8,
    Orpheus : 9,
    PokerImac : 1,
    Lolimac : 12,
    Teller : 16,
    BlogWriter : 17,
    LyonVelib : 18,
    StrasbourgTourism : 19,
    WebAgency : 20

}
  
  export default index